import { Component, OnInit } from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { AppService } from './app.service';
import { UserRole } from './users/roles.enum';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    model: any[] = [];
    currentUser: any;

    constructor(
        public app: AppMainComponent, 
        private appService: AppService, 
    ) {}

    hasAccess(item: any): boolean {
        return item.roles.includes(this.currentUser.roles[0]);
    }

    async ngOnInit(): Promise<void> {
        this.model = [
            {
                label: 'Jobs',
                icon: 'pi pi-fw pi-home',
                routerLink: ['/'],
                roles: [UserRole.ADMIN, UserRole.USER, UserRole.REP]
            },
            {
                label: 'Forms',
                icon: 'pi pi-fw pi-list',
                routerLink: ['forms-manager'],
                roles: [UserRole.ADMIN, UserRole.USER]
            },
            {
                label: 'Settings', icon: 'pi pi-fw pi-cog', routerLink: ['/settings'],
                items: [
                    { label: 'Photo Labels', icon: 'pi pi-fw pi-image', routerLink: ['/settings/photo-labels'] },
                ],
                roles: [UserRole.ADMIN, UserRole.USER]
            },
        ];

        this.currentUser = await this.appService.getCurrentUser();
    }
}
