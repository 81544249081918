import { Component } from '@angular/core';

@Component({
    selector: 'app-footer',
    template: `
        <div class="layout-footer">
            <div class="grid">
                <div class="col">
                    <!-- <img src="/assets/layout/images/csi-sitecheck.png" alt="CSI / SiteCheck"> -->
                    <div class="layout-footer-appname"><h4>Inspection Management System</h4></div>
                </div>
                <div class="col">
                    <span>&copy; 2020-{{ currentYear }} - Collateral Specialists</span>
                </div>
            </div>
        </div>
    `
})
export class AppFooterComponent {
    get currentYear(): number {
        return new Date().getFullYear();
    }
}
