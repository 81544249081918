import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';


export const authGuard: CanActivateFn = (
  route,
  state
): boolean | UrlTree | Promise<boolean | UrlTree> | Observable<boolean | UrlTree> => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const accessToken = authService.token;
  if (!accessToken) {
    return router.createUrlTree(['/login']);
  }
  
  if (authService.isTokenValid(accessToken)) {
    return true;
  }

  return router.createUrlTree(['/login']);
};