<!-- <p-toast></p-toast> -->
<div class="card">
  <p-toolbar>
    <ng-template pTemplate="left">
      <button pButton pRipple type="button" icon="pi pi-plus"
              (click)="newLabel()"
              class="p-button-rounded p-button-text p-button-info" pTooltip="Add new photo label"></button>
    </ng-template>
  </p-toolbar>
<p-table [value]="photoLabels" (onRowReorder)="rowReorder($event)" (onEditComplete)="this.editComplete($event)">
  <ng-template pTemplate="header">
    <tr>
      <th style="width:3rem"></th>
      <th>
        Photo Label
      </th>
      <th style="width:15rem"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-photoLabel let-index="rowIndex">
    <tr style="height:6rem" [pReorderableRow]="index">
      <td>
        <span class="pi pi-bars" pReorderableRowHandle></span>
      </td>
      <td pEditableColumn>
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input pInputText type="text" [(ngModel)]="photoLabel.label">
          </ng-template>
          <ng-template pTemplate="output">
            {{photoLabel.label}}
          </ng-template>
        </p-cellEditor>
      </td>
      <td style="text-align:center">
        <button pButton pRipple type="button" pCancelEditableRow icon="pi pi-trash" (click)="deleteLabel(index)" class="p-button-rounded p-button-text p-button-danger"></button>
      </td>
    </tr>
  </ng-template>
</p-table>
</div>
