<p-toast key="loginToast" position="top-center"></p-toast>
<p-dialog header="Password Reset Request" [modal]="true" [(visible)]="passwordResetVisible" [style]="{ width: '25rem' }">
    <span class="p-text-secondary block mb-5">Enter the email address associated with your login. If your email matches a valid login, you will receive an email with a password reset link.</span>
    <form #resetForm="ngForm" (submit)="submitResetPassword(passwordResetEmail)">
        <div class="flex align-items-center gap-3 mb-5">
            <input #email pInputText placeholder="Enter your email address" id="email" class="flex-auto" autocomplete="off" name="email" [(ngModel)]="passwordResetEmail" required email/>
        </div>
        <div class="flex justify-content-end gap-2">
            <p-button label="Cancel" severity="secondary" (onClick)="passwordResetVisible = false" />
            <p-button label="Continue" type="submit" [disabled]="resetForm.invalid" />
        </div>
    </form>
</p-dialog>
<div class="login-body">
    <div class="login-panel p-fluid">
        <div class="login-panel-header">
            <img class="logo-image" src="assets/layout/images/login/sitecheck-logo.jpg">
        </div>
        <div class="login-panel-content">
            <form [formGroup]="loginForm" (ngSubmit)="login()">
            <div class="grid">
                <div class="col-12">
                    <h1>Inspection Management System</h1>
                    <h2>Welcome, please use the form to sign-in</h2>
                    <!-- <span class="text-center"><small><i>For official use only by authorized users</i></small></span> -->
                </div>
                
                <div class="col-12">                
                    <div class="p-field p-fluid">
                          <input id="username" pInputText formControlName="username" 
                            [ngClass]="{'p-invalid': isFieldInvalid('username')}" placeholder="Username"/>
                      </div>
                </div>
                <div class="col-12">
                    <div class="p-field p-fluid">
                          <input id="password" pInputText type="password" formControlName="password" 
                            [ngClass]="{'p-invalid': isFieldInvalid('password')}" placeholder="Password"/>
                      </div>
                  
                </div>
                <div class="col-6" style="padding-top: 1.25em">
                    <p (click)="passwordResetVisible=true" style="cursor: pointer; color: blue; text-decoration: underline;">Forgot Password?</p>
                </div>
                <div class="col-6" style="text-align: right">
                    <button pButton pRipple type="submit" label="NEXT" style="width: 100%" [disabled]="loginForm.invalid"></button>
                </div>
            </div>
            </form>
        </div>
    </div>
</div>

