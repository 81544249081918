<p-tabView #tabView (onChange)="tabChange($event)">
  <p-tabPanel header="Designer" leftIcon="pi pi-file">
    <div class="p-panel">
      <div class="p-panel-content" *ngIf="currentTabIndex === 0">
        <div>
          <form-builder #formBuilder [form]="formDef"></form-builder>
        </div>
      </div>
      <div class="p-panel-footer">
        <hr>
        <div class="p-grid">
          <div class="p-col-2">
            <button pButton type="button" label="Save" class="p-button-success" (click)="save(formBuilder)"></button>&nbsp;&nbsp;
            <button pButton type="button" label="Cancel" class="p-button-danger" (click)="cancel($event)"></button>
          </div>
        </div>
      </div>
    </div>
  </p-tabPanel>
  <p-tabPanel header="Preview">
    <div class="p-panel">
      <div class="p-panel-content" *ngIf="currentTabIndex === 1">
        <div>
          <formio [form]="formDef"></formio>
        </div>
      </div>
      <div class="p-panel-footer">
        <hr>
        <div class="p-grid">
          <div class="p-col-2">
            <button pButton type="button" label="Save" class="p-button-success" (click)="save(formBuilder)"></button>&nbsp;&nbsp;
            <button pButton type="button" label="Cancel" class="p-button-danger" (click)="cancel($event)"></button>
          </div>
        </div>
      </div>
    </div>
  </p-tabPanel>

  <p-tabPanel header="JSON Editor">
    <div class="p-panel">
      <div class="p-panel-content" *ngIf="currentTabIndex === 2">
          <!-- <json-editor #jsoneditor [debug]="true" [options]="editorOptions" [data]="formDef" (change)="jsonEditorChange($event)" style="min-height: 800px;"></json-editor> -->
      </div>
      <div class="p-panel-footer">
        <hr>
        <div class="p-grid">
          <div class="p-col-2">
            <button pButton type="button" label="Save" class="p-button-success" (click)="save(formBuilder)"></button>&nbsp;&nbsp;
            <button pButton type="button" label="Cancel" class="p-button-danger" (click)="cancel($event)"></button>
          </div>
        </div>
      </div>
    </div>
  </p-tabPanel>
  <p-tabPanel header="Key Properties Editor">
    <div class="p-panel">
      <div class="p-panel-content" *ngIf="currentTabIndex === 3">
        <p-table [value]="formInputComponents" dataKey="componentId" editMode="row">
          <ng-template pTemplate="header">
            <tr>
              <th>Label</th>
              <th>API Key</th>
              <th>PDF Form Key</th>
              <th style="width:15rem"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-inputComponent let-editing="editing" let-ri="rowIndex">
            <tr [pEditableRow]="inputComponent">
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input pInputText type="text" [(ngModel)]="inputComponent.label" style="width: 12rem" required>
                  </ng-template>
                  <ng-template pTemplate="output">
                    <span style="width: 12rem">{{inputComponent.label}}</span>
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input pInputText type="text" [(ngModel)]="inputComponent.key" style="width: 12rem" required>
                  </ng-template>
                  <ng-template pTemplate="output">
                    <span style="width: 12rem">{{inputComponent.key}}</span>
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input pInputText type="text" [(ngModel)]="inputComponent.properties.pdfFormKey" style="width: 12rem" required>
                  </ng-template>
                  <ng-template pTemplate="output">
                    <span style="width: 12rem">{{inputComponent.properties.pdfFormKey}}</span>
                  </ng-template>
                </p-cellEditor>
              </td>
              <td style="text-align:center">
                <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditInit(inputComponent)" class="p-button-rounded p-button-text"></button>
                <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSave(inputComponent)" class="p-button-rounded p-button-text p-button-success p-mr-2"></button>
                <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancel(inputComponent)" class="p-button-rounded p-button-text p-button-danger"></button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <div class="p-panel-footer">
        <hr>
        <div class="p-grid">
          <div class="p-col-2">
            <button pButton type="button" label="Save" class="p-button-success" (click)="save(formBuilder)"></button>&nbsp;&nbsp;
            <button pButton type="button" label="Cancel" class="p-button-danger" (click)="cancel($event)"></button>
          </div>
        </div>
      </div>
    </div>
  </p-tabPanel>
</p-tabView>
