<!-- <p-toast></p-toast> -->
<p-confirmDialog key="jobsManagerConfirmDialog" header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<div class="p-grid">
  <div class="p-col-12 h-100">
    <div class="p-panel h-100">
      <div class="p-panel-header">
        <h4>Jobs Manager {{'(' + currentUser?.userName + ')'}} {{currentUserRoleName}}</h4>
      </div>
      <div class="p-panel-content">
        <p-table #dt1 
          [value]="jobs"
          styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm"
          [globalFilterFields]="['scJobId', 'scInsuredName', 'scInsuredAddress', 'scInsuredCity', 'scInsuredState', 'scInsuredZip', 'scPolicyNumber', 'scClientTrackingNumber', 'scInsuredContactName']"
          [scrollable]="true"
          scrollHeight="65vh"
          [resizableColumns]="true"
          [filterDelay]="500"
          [rowHover]="true"
          >
          
          <ng-template pTemplate="caption">
            <div class="flex flex-row flex-row-reverse align-items-center gap-2">
              <div>
                <button pButton pRipple type="button" icon="pi pi-undo"
                  class="p-button-rounded p-button-text shadow-3"
                  pTooltip="Reset the view and clear all filters and search" tooltipPosition="top"
                  ></button>
              </div>
              <div class="flex">
                <span class="p-input-icon-left p-ml-auto">
                  <i class="pi pi-search"></i>
                  <input pInputText type="text" (input)="dt1.filterGlobal($any($event).target.value, 'contains')"
                    placeholder="Search for..." />
                </span>
              </div>
            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th pSortableColumn="scJobId">
                Job# <p-sortIcon field="scJobId"></p-sortIcon>
              </th>
              <th pSortableColumn="scCompanyName">
                Company <p-sortIcon field="scCompanyName"></p-sortIcon>
                <p-columnFilter type="text" field="scCompanyName" display="menu"></p-columnFilter>
              </th>
              <th pSortableColumn="scFormTypeName">
                Form <p-sortIcon field="scFormTypeName"></p-sortIcon>
                <p-columnFilter type="text" field="scFormTypeName" display="menu"></p-columnFilter>
              </th>
              <th class="text-center" pSortableColumn="scRequestDate">
                Requested <p-sortIcon field="scRequestDate"></p-sortIcon>
                <p-columnFilter type="date" field="scRequestDate" display="menu"></p-columnFilter>
              </th>
              <th pResizableColumn>
                Contact
              </th>
              <th pSortableColumn="scScheduledDate">
                Scheduled <p-sortIcon field="scScheduledDate"></p-sortIcon>
                <p-columnFilter type="date" field="scScheduledDate" display="menu"></p-columnFilter>
              </th>
              <th class="text-center">
                Due Date
              </th>
              <th pResizableColumn pSortableColumn="scInsuredName">
                Insured Name <p-sortIcon field="scInsuredName"></p-sortIcon>
                <p-columnFilter type="text" field="scInsuredName" display="menu"></p-columnFilter>
              </th>
              <th pResizableColumn>
                Insured Address
                <p-columnFilter type="text" field="scInsuredAddress" display="menu"></p-columnFilter>
              </th>
              <th pSortableColumn="scCoordinatorName">
                Coordinator <p-sortIcon field="scCoordinatorName"></p-sortIcon>
                <p-columnFilter type="text" field="scCoordinatorName" display="menu"></p-columnFilter>
              </th>
              <th *ngIf="!(currentUserPrimaryRole===UserRole.REP)" pSortableColumn="scRepName">
                Rep Name <p-sortIcon field="scRepName"></p-sortIcon>
                <p-columnFilter type="text" field="scRepName" display="menu"></p-columnFilter>
              </th>
              <th pSortableColumn="scJobStatus">
                Job Status <p-sortIcon field="scJobStatus"></p-sortIcon>
                <p-columnFilter type="text" field="scJobStatus" display="menu"></p-columnFilter>
              </th>
              <!-- <th pSortableColumn="trackingStatus">
                Form Status <p-sortIcon field="trackingStatus"></p-sortIcon>
                <p-columnFilter type="text" field="trackingStatus" display="menu"></p-columnFilter>
              </th> -->
              <th style="width:15rem" class="text-center">Actions</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-job>
            <tr>
              <td class="text-center text-indigo-400">
                <b>{{job.scJobId}}</b>
              </td>
              <td>{{job.scCompanyName}}</td>
              <td>
                <div class="flex flex-row align-items-center gap-2">
                  <div>
                    <button pButton pRipple type="button" icon="pi pi-file-pdf"
                      class="p-button-rounded p-button-text shadow-3"
                      pTooltip="View the PDF form and print" tooltipPosition="top"
                      (click)="openPdf(job, $event);"
                      ></button>
                  </div>
                  <div class="flex vertical-align-middle">{{job.scFormTypeName}}</div>
                </div>
              </td>
              <td class="text-center">
                <span [pTooltip]="datePipe.transform(job.scRequestDate, 'short') || ''" tooltipPosition="top">
                  {{job.scRequestDate | date: 'shortDate'}}
                </span>
              </td>
              <td class="text-wrap w-17rem">
                <div class="flex flex-column">
                  <div class="text-gray-700">
                    <span pTooltip="{{job.scInsuredContactEmail.toUpperCase()}}"  tooltipPosition="top">
                      <b>{{job.scInsuredContactName.toUpperCase()}}</b>
                    </span>
                  </div>
                  <div class="flex flex-row gap-2">
                    <!-- <span class="text-gray-500">
                      <small><b>TEL#</b></small>
                    </span> -->
                    <div class="text-gray-500">
                      <span>
                        <small><b>{{job.scInsuredContactPhone | phoneNumber}}</b></small>
                      </span>
                    </div>
                  </div>    
                </div>
              </td>
              <td>
                <div class="flex flex-row align-items-center gap-2">
                  <div>
                    <p-overlayPanel #op (onShow)="showDatePanel($event, job)" (onHide)="scheduledDate = null">
                      <div class="flex flex-row align-items-center gap-2">
                        <div>
                            <p-calendar
                            [(ngModel)]="scheduledDate" 
                            [showTime]="true" 
                            [showSeconds]="false" 
                            [showButtonBar]="true"
                            [showOnFocus]="false"
                            [iconDisplay]="'button'"
                            [showIcon]="true"
                            ></p-calendar>
                        </div>
                        <div>
                          <button pButton pRipple type="button" icon="pi pi-check-circle" (click)="updateScheduledDate(job, op)"
                            class="p-button-rounded p-button-text p-button-success shadow-3"></button>
                        </div>
                        <div>
                          <button pButton pRipple type="button" icon="pi pi-times-circle" (click)="op.hide()"
                            class="p-button-rounded p-button-text p-button-danger shadow-3"></button>
                        </div>
                      </div>
                    </p-overlayPanel>
                  </div>
                  <div class="flex vertical-align-middle">
                    <button pButton pRipple type="button" icon="pi pi-calendar" (click)="op.toggle($event)"
                    class="p-button-rounded p-button-text p-button-success shadow-3"
                    pTooltip="Open the calendar to schedule the inspection" tooltipPosition="top"
                    ></button>
                  </div>
                  <div class="flex vertical-align-middle">
                    {{job.scScheduledDate | date: 'short'}}
                  </div>
                </div>
              </td>
              <td class="text-center">
                <p-tag 
                  [style]="{width: '4rem'}"
                  [severity]="getDueDateStyle(job.scRequestDate, currentUserPrimaryRole)" 
                  [value]="datePipe.transform(calculateDueDate(job.scRequestDate, currentUserPrimaryRole), 'shortDate') || ''">
                </p-tag>
              </td>
              <td class="text-wrap w-60rem">
                <div class="flex flex-column">
                  <div class="text-gray-700">
                    <b>{{job.scInsuredName.toUpperCase()}}</b>
                  </div>
                  <div class="flex flex-row gap-2">
                    <div class="text-gray-700">
                      <span class="text-gray-500">
                        <small><b>POL# </b></small>
                      </span>
                      <span>
                        <small><b>{{job.scPolicyNumber.toUpperCase()}}</b></small>
                      </span>
                    </div>
                    <div class="text-gray-700">
                      <span class="text-gray-500">
                        <small><b>TRK# </b></small>
                      </span>
                      <span>
                        <small><b>{{job.scClientTrackingNumber ? job.scClientTrackingNumber.toUpperCase() : 'N/A'}}</b></small>
                     </span>
                    </div>
                  </div>    
                </div>
              </td>
              <td>
                <div class="text-wrap text-gray-700">
                  <b>{{job.scInsuredAddress.toUpperCase()}}</b>
                </div>
                <div class="text-gray-500">
                  <small><B>{{job.scInsuredCity}}, {{job.scInsuredState}} {{job.scInsuredZip}}</B></small>
                </div>
              </td>
              <td>{{job.scCoordinatorName}}</td>
              <td *ngIf="!(currentUserPrimaryRole===UserRole.REP)">
                <div class="text-wrap text-gray-700">
                  <b>{{job.scRepName}}</b>
                </div>
                <div class="text-gray-600">
                  <small><b>{{job.scRepPhone | phoneNumber }}</b></small>
                </div>
                <div class="text-gray-500">
                  <!-- <span style="cursor: pointer;" pTooltip="Click to copy the email" tooltipPosition="bottom" (click)="copyToClipboard(job.scRepEmail.toLowerCase())"> -->
                    <small><B>{{job.scRepEmail.toLowerCase()}}</B></small>
                  <!-- </span> -->
                  &nbsp;
                  <span class="pi pi-clone" style="cursor: pointer; font-size: .85rem;" pTooltip="Click to copy the email" tooltipPosition="bottom" (click)="copyToClipboard(job.scRepEmail.toLowerCase())"></span>
                </div>
              </td>
              <td *ngIf="(currentUserPrimaryRole!==UserRole.REP)" class="text-center">
                <p-overlayPanel #opStatus (onShow)="showJobStatusPanel(job.scJobStatusId, $event)">
                  <div class="flex flex-row align-items-center gap-2">
                    <div>
                      <p-dropdown
                        [(ngModel)]="selectedJobStatusId" 
                        [options]="getJobStatusDropdownOptions()"
                        [style]="{width: '12rem'}"
                        optionLabel="name" optionValue="code">
                      </p-dropdown>
                    </div>
                    <div>
                      <button pButton pRipple type="button" icon="pi pi-check-circle" (click)="updateJobStatus(job, opStatus)"
                        class="p-button-rounded p-button-text p-button-success shadow-3"></button>
                    </div>
                    <div>
                      <button pButton pRipple type="button" icon="pi pi-times-circle" (click)="opStatus.hide()"
                        class="p-button-rounded p-button-text p-button-danger shadow-3"></button>
                    </div>
                  </div>
                </p-overlayPanel>
                <p-tag (click)="opStatus.toggle($event)"
                  [style]="{cursor: 'pointer', width: '8rem', 'font-size': '13px'}"
                  [severity]="'info'" 
                  [value]="getJobStatusName(job.scJobStatusId)">
                </p-tag>
              </td>
              <td *ngIf="(currentUserPrimaryRole===UserRole.REP)" class="text-center">
                <p-tag
                  [style]="{width: '8rem', 'font-size': '13px'}"
                  [severity]="'info'" 
                  [value]="getJobStatusName(job.scJobStatusId)">
                </p-tag>
              </td>
              <!-- <td class="text-center"><span [class]="'product-badge status-' + job.trackingStatus.toLowerCase()">{{job.trackingStatus}}</span></td> -->
              <td style="text-align:center">
                <div class="flex flex-row justify-content-between align-items-center">
                  <div *ngIf="!(currentUserPrimaryRole===UserRole.REP)" >
                    <button pButton pRipple type="button" icon="pi pi-envelope" (click)="sendLinkEmail(job, $event)"
                    class="p-button-rounded p-button-text shadow-3" pTooltip="Email inspection form link to assigned rep"
                    tooltipPosition="top"></button>
                  </div>
                  <div>
                    <button pButton pRipple type="button" icon="pi pi-comments" (click)="openNotesDialog(job)"
                    class="p-button-rounded p-button-text shadow-3" pTooltip="Open job notes" tooltipPosition="top"></button>
                  </div>
                  <div *ngIf="(currentUserPrimaryRole===UserRole.REP && job.scJobStatusId === JOB_STATUS.QC)">
                    <button pButton pRipple type="button" icon="pi pi-clock" (click)="openTimeSheet(job, $event)"
                    class="p-button-rounded p-button-text p-button-success shadow-3" [disabled]="!job.formId"
                    pTooltip="Open CSI online timesheet reporting form" tooltipPosition="top"
                    ></button>
                  </div>
                    <div *ngIf="canShowInspectionFormButton(job)">
                    <button pButton pRipple type="button" icon="pi pi-file" (click)="openInspection(job, $event)"
                    class="p-button-rounded p-button-text p-button-success shadow-3" [disabled]="!job.formId"
                    pTooltip="Open report form and photo tool" tooltipPosition="top"
                    ></button>
                  </div>
                  <!-- <div *ngIf="!(currentUserPrimaryRole===UserRole.REP && job.scJobStatusId === JOB_STATUS.QC)">
                    <button pButton pRipple type="button" icon="pi pi-images" (click)="openPhotoUpload(job, $event)"
                    class="p-button-rounded p-button-text p-button-success shadow-3"
                    pTooltip="Open the photo upload form for review and editing" tooltipPosition="top"
                    ></button>
                  </div> -->
                  <div *ngIf="currentUserPrimaryRole === UserRole.REP && job.scJobStatusId !== JOB_STATUS.QC && job.scJobStatusId !== JOB_STATUS.ON_HOLD && job.scJobStatusId !== JOB_STATUS.INSURED">
                    <button pButton pRipple type="button" icon="pi pi-check-circle" (click)="saveFinal(job)"
                    class="p-button-rounded p-button-text p-button-danger shadow-3"
                    pTooltip="Submit to QC team for review" tooltipPosition="top"
                  ></button>  
                  </div>
                  <div *ngIf="!(currentUserPrimaryRole===UserRole.REP)">
                    <button pButton pRipple type="button" icon="pi pi-cloud-upload" (click)="uploadInspection(job)"
                    class="p-button-rounded p-button-text shadow-3 p-button-danger"
                    pTooltip="Upload completed inspection and photos to SiteCheck system" tooltipPosition="top"
                    ></button>
                  </div>
                </div>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="summary">
            <div *ngIf="jobs && jobs.length" class="flex flex-row p-1 gap-2">
                <div class="flex flex-row flex-grow-1 shadow-3 rounded-2 p-2 gap-2">
                  <div *ngFor="let status of statusTotals | keyvalue">
                    <p-tag class="status-tag" [style]="{width: '12rem', 'font-size': '16px'}" [severity]="'info'" [value]="status.key + ': ' + status.value"></p-tag>
                  </div>
                  <p-tag *ngIf="!isShowDelivered && currentUserPrimaryRole!==UserRole.REP" class="status-tag" [style]="{width: '12rem', 'font-size': '16px', cursor: 'pointer'}" [severity]="'secondary'" [value]="'Delivered'" (click)="showDelivered()"></p-tag>
                  <p-tag *ngIf="isShowDelivered && currentUserPrimaryRole!==UserRole.REP" class="status-tag" [style]="{width: '12rem', 'font-size': '16px', cursor: 'pointer'}" [severity]="'info'" [value]="'Delivered: ' + statusTotals['Delivered']" (click)="showDelivered()"></p-tag>
                </div>
                <div class="flex flex-row justify-content-end shadow-3 rounded-2 p-2 gap-2">
                  <div>
                    <p-tag class="status-tag" [style]="{width: '12rem', 'font-size': '16px'}" [severity]="'secondary'" [value]="'Total: ' + jobs.length"></p-tag>
                  </div>
                </div>
            </div>
            
        </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>
