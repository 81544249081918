import { Pipe, PipeTransform } from '@angular/core';
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';

@Pipe({
  name: 'phoneNumber',
  standalone: true
})
export class PhoneNumberPipe implements PipeTransform {
  private phoneUtil = PhoneNumberUtil.getInstance();

  transform(value: string): string {
    if (!value) {
      return 'Unknown/Invalid Phone #';
    }

    try {
      // Parse the phone number
      const phoneNumber = this.phoneUtil.parse(value, 'US');

      // Check if the phone number is valid
      if (!this.phoneUtil.isValidNumber(phoneNumber)) {
        return 'Unknown/Invalid Phone #';
      }

      // Format the number in (000) 000-0000 format
      const formattedNumber = this.phoneUtil.format(phoneNumber, PhoneNumberFormat.NATIONAL);
      return formattedNumber;

    } catch (error) {
      // If parsing or validation fails, return "Unknown"
      return 'Unknown/Invalid Phone #';
    }
  }
}