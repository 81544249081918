<div class="exception-body accessdenied">
    <div class="exception-panel">
        <div class="exception-image">
            <img src="assets/layout/images/exception/icon-access.png">
        </div>

        <div class="exception-detail">
            <h1>ACCESS DENIED</h1>
            <p>You do not have the necessary permissions.</p>
            <p-button label="Home Page" [routerLink]="['/']"></p-button>
        </div>
    </div>
</div>
