import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { environment } from'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  env = environment;
  
  constructor(private http: HttpClient) { }

  async passwordReset(email: string): Promise<void> {
    try {
      await firstValueFrom( this.http.post(`${this.env.baseApiUrl}/auth/request-password-reset`, { email }) );
    } catch (e) { 
      console.error(`Unable to submit password reset request.\n${e}`) 
    }
  }
}

