import { IEnv } from './environment.interface';

const protocol = window.location.protocol;
const hostname = window.location.hostname;
const port = window.location.port? `:${window.location.port}` : '';
const baseUrl = `${protocol}//${hostname}${port}`;
const baseApiUrl = `${baseUrl}/api`;

export const environment: IEnv = {
  production: true,
  baseUrl,
  baseApiUrl,
};