<div class="p-grid">
        <div class="p-col">
                <image-cropper [imageFile]="file" [imageQuality]="100" [imageChangedEvent]="imageCropped"
                        [maintainAspectRatio]="false" [containWithinAspectRatio]="true" [transform]="transform"
                        [autoCrop]="true" [canvasRotation]="canvasRotation" (imageCropped)="imageCropped($event)"
                        (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady(sourceImageDimensions)"
                        (loadImageFailed)="loadImageFailed()" [style.display]="showCropper ? null : 'none'">
                </image-cropper>
                <div style="text-align: center; margin-top: 10px;">
                        <div class="flex flex-row justify-content-center align-items-center gap-2">
                        <!--      <button pButton pRipple type="button" icon="pi pi-window-minimize" (click)="crop()"-->
                        <!--              class="p-button-rounded p-button-text p-button-info"-->
                        <!--              pTooltip="Crop" tooltipPosition="top"-->
                        <!--              showDelay="500" hideDelay="150"></button>-->
                        <p-button pRipple icon="pi pi-replay" (click)="rotate('left')" [rounded]="true"
                                [outlined]="true" severity="info" pTooltip="Rotate Left"
                                tooltipPosition="top"></p-button>
                        <p-button pRipple icon="pi pi-refresh" (click)="rotate('right')" [rounded]="true"
                                [outlined]="true" severity="info" pTooltip="Rotate Right"
                                tooltipPosition="top"></p-button>
                        <p-button pRipple icon="pi pi-plus" (click)="zoomIn()" [rounded]="true" [outlined]="true"
                                severity="info" pTooltip="Zoom In" tooltipPosition="top"></p-button>
                        <p-button pRipple icon="pi pi-minus" (click)="zoomOut()" [rounded]="true" [outlined]="true"
                                severity="info" pTooltip="Zoom Out" tooltipPosition="top"></p-button>
                        <p-button pRipple type="button" icon="pi pi-check" (click)="save()" [rounded]="true"
                                [outlined]="true" severity="success">
                        </p-button>
                        <p-button pRipple type="button" icon="pi pi-times" (click)="cancel()" [rounded]="true"
                                [outlined]="true" severity="danger">
                        </p-button>

                        </div>
                </div>


        </div>
</div>