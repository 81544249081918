import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class LogoutService {
  
  constructor(private authService: AuthService) { }

  async logout() {
    this.authService.logout();
  }
}

