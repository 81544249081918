<div class="layout-topbar">

    <a href="#" class="layout-menubutton" [ngClass]="{'layout-menubutton-active': app.menuActive}" (click)="app.onMenuButtonClick($event)">
        <div class="layout-menubutton-icon"></div>
    </a>

    <div class="layout-topbar-grid">
        <div class="layout-topbar-grid-column layout-topbar-grid-column-fixed">
            <a href="#" class="layout-logo">
                <img src="assets/layout/images/csi-sitecheck.png" alt="sapphire-layout" />
            </a>
        </div>

        <div class="layout-topbar-grid-column">
            <app-menu></app-menu>
        </div>

<!--        <div class="layout-topbar-grid-column layout-topbar-grid-column-fixed">-->
<!--            <span class="layout-topbar-search">-->
<!--                <span class="p-input-icon-right">-->
<!--                    <input type="text" pInputText placeholder="Search"/>-->
<!--                    <i class="pi pi-search"></i>-->
<!--                </span>-->
<!--            </span>-->
<!--        </div>-->

        <div class="layout-topbar-grid-column layout-topbar-grid-column-fixed">
            <a href="#" class="profile-menu-button" style="color: white; height: 32px !important;" (click)="app.onTopbarUserMenuButtonClick($event)">
<!--                <img src="assets/layout/images/avatar.png" alt="sapphire-layout" />-->
              <i class="pi pi-user" style="font-size: 1.5rem; padding: 4px"></i>
            </a>
            <ul class="layout-profile-menu fadeInDown" [ngClass]="{'layout-profile-menu-active': app.topbarUserMenuActive}" (click)="app.onTopbarUserMenuClick($event)">
<!--                <li class="layout-profile-menu-search">-->
<!--                    <span class="md-inputfield">-->
<!--                        <input type="text" pInputText />-->
<!--                        <label>Search</label>-->
<!--                    </span>-->
<!--                </li>-->
                <li role="menuitem">
                    <a href="#" pRipple (click)="app.onTopbarSubItemClick($event, '')">
                        <i class="pi pi-user"></i>
                        <span>Profile</span>
                    </a>
                </li>
<!--                <li role="menuitem">-->
<!--                    <a href="#" pRipple (click)="app.onTopbarSubItemClick($event, null)">-->
<!--                        <i class="pi pi-envelope"></i>-->
<!--                        <span>Inbox</span>-->
<!--                    </a>-->
<!--                </li>-->
                <li role="menuitem">
                    <a href="#" pRipple (click)="app.onTopbarSubItemClick($event, '')">
                        <i class="pi pi-cog"></i>
                        <span>Settings</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a href="#" pRipple (click)="app.onTopbarSubItemClick($event, 'logout')">
                        <i class="pi pi-times"></i>
                        <span>Logout</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>

</div>
