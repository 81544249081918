import { Component, OnInit } from '@angular/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { CardModule } from 'primeng/card';
import { PasswordModule } from 'primeng/password';
import { ReactiveFormsModule, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { FormsModule } from '@angular/forms';
import { FloatLabelModule } from 'primeng/floatlabel';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';


@Component({
  selector: 'password-reset',
  standalone: true,
  imports: [
    ButtonModule, 
    InputTextModule, 
    CommonModule, 
    CardModule, 
    PasswordModule, 
    ReactiveFormsModule, 
    ToastModule, 
    FormsModule, 
    FloatLabelModule,
    MessagesModule,
    MessageModule,
  ],
  templateUrl: './password-reset.component.html',
  styleUrl: './password-reset.component.scss'
})
export class PasswordResetComponent implements OnInit {
  token: string = '';
  newPassword: string = '';
  passwordResetForm: FormGroup;
  showPasswordResetForm = true;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private authService: AuthService,
    private messageService: MessageService,
    private router: Router,
  ) {
    this.passwordResetForm = this.fb.group(
      {
        newPassword: ['', [Validators.required, Validators.minLength(6)]],
        confirmPassword: ['', Validators.required],
      },
      { validator: this.passwordsMatchValidator }
    );
  }

  ngOnInit(): void {
    this.authService.logout();
    this.token = this.route.snapshot.queryParams['token'];
  }

  passwordsMatchValidator(form: FormGroup) {
    const newPassword = form.get('newPassword')?.value;
    const confirmPassword = form.get('confirmPassword')?.value;
    return newPassword === confirmPassword ? null : { mismatch: true };
  }

  onSubmit() {
    if (this.passwordResetForm.valid && this.token) {
      const newPassword = this.passwordResetForm.get('newPassword')?.value;
      this.authService.passwordReset(this.token, newPassword).subscribe({
        next: () => {
          this.showPasswordResetForm = false;
          this.messageService.add({ severity: 'success', summary: 'Password Reset', detail: 'Password reset successful', key: 'passwordResetToast' });
          setTimeout(async () => {
            const nav = await this.router.navigate(['/login']);
          }, 2500);
        },
        error: (error) => {
          this.messageService.add({ severity: 'error', summary: 'Password Reset', detail: 'Password reset failed', key: 'passwordResetToast' });
        }  
      });
      return;
    }
    this.messageService.add({ severity: 'error', summary: 'Password Reset', detail: 'Password reset failed', key: 'passwordResetToast' });
    return;
  }

  isFieldInvalid(fieldName: string): boolean {
    const control = this.passwordResetForm.get(fieldName);
    return control?.invalid && (control.dirty || control.touched) || false;
  }
}
