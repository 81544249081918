<!-- <p-toast></p-toast> -->
<p-confirmDialog key="inspectionGuestConfirmDialog" header="Inspection Form Confirmation" width="425" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<div class="card h-100 block" *ngIf="!isLoading">
  <!-- <h2>
    {{pageTitle}}
  </h2> -->
  <div class="p-panel" *ngIf="!isLinkValid">
    <div class="p-panel-content">
      <h5>This inspection assignment is invalid.</h5>
      <h5>Please contact your coordinator for assistance.</h5>
    </div>
  </div>
  <div class="p-panel" *ngIf="isInspectionImmutable && isLinkValid">
    <div class="p-panel-content">
      <h5>This inspection assignment has been closed and is no longer available for editing or photo uploads.</h5>
      <h5>Please contact your coordinator if you have changes or additional photos to add.</h5>
    </div>
  </div>
  <div *ngIf="isFormClosed && isLinkValid">
    <div class="h-19rem">
      <div>
        <h2>The inspection window has been closed. You can now close this browser window or tab.</h2>
      </div>
    </div>
  <!-- <p-tabView  *ngIf="!isInspectionImmutable && isLinkValid">
    <p-tabPanel header="Photos" leftIcon="pi pi-image" tooltip="Upload photos for this inspection.">
      <div *ngIf="scJob">
        <app-upload [scJob]="scJob" (hasUnsavedPhotoChanges)="hasUnsavedPhotoChanges=$event"></app-upload>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Form" leftIcon="pi pi-file" tooltip="View and fill the inspection form.">
      <div *ngIf="scJob">
        <app-inspection-form [scJob]="scJob" (hasUnsavedFormChanges)="hasUnsavedFormChanges=$event"></app-inspection-form>
      </div>
    </p-tabPanel>
  </p-tabView> -->
</div>
