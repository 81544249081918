<p-toast key="passwordResetToast" position="top-center"></p-toast>
<div *ngIf="showPasswordResetForm" class="password-reset-container">
  <h3>Password Reset</h3>

  <form [formGroup]="passwordResetForm" (ngSubmit)="onSubmit()">
    <div class="p-field p-fluid">
      <p-floatLabel>
        <p-password formControlName="newPassword" toggleMask feedback="true"
          [ngClass]="{ 'p-invalid': isFieldInvalid('newPassword') }"></p-password>
        <label for="newPassword">New Password</label>
      </p-floatLabel>
      <span *ngIf="isFieldInvalid('newPassword')">
        <small>
          Password is required and must be at least 6 characters long
        </small>
      </span>
    </div>

    <div class="p-field p-fluid">
      <p-floatLabel>
        <p-password formControlName="confirmPassword" toggleMask feedback="false"
          [ngClass]="{ 'p-invalid': isFieldInvalid('confirmPassword') }"></p-password>
        <label for="confirmPassword">Confirm Password</label>
      </p-floatLabel>
      <span *ngIf="isFieldInvalid('confirmPassword')">
        <small>Please confirm your password</small>
      </span>
      <span *ngIf="!isFieldInvalid('confirmPassword') && passwordResetForm.errors?.['mismatch'] && passwordResetForm.get('confirmPassword')?.touched">
        <small>Passwords do not match</small>
      </span>
    </div>

    <div class="p-field">
      <button pButton type="submit" label="Reset Password" [disabled]="passwordResetForm.invalid"></button>
    </div>
  </form>
</div>