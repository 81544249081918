import { Component, OnInit, Input, OnDestroy, OnChanges, SimpleChange, SimpleChanges, ChangeDetectorRef} from '@angular/core';
import { NgForm, NgModel, FormsModule } from '@angular/forms';
import { StyleClassModule } from 'primeng/styleclass';
import { NgClass } from '@angular/common';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { Table, TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { ChipsModule } from 'primeng/chips';
import { NotesService } from './notes.service';
import { ISqlJob } from '../jobs/sql-job.interface';
import { INote } from './interfaces/note.interface';
import { DatePipe } from '@angular/common';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { BadgeModule } from 'primeng/badge';

@Component({
  standalone: true,
  imports: [
    StyleClassModule,
    NgClass,
    CommonModule,
    ButtonModule,
    CardModule,
    ScrollPanelModule,
    TableModule,
    DialogModule,
    InputTextModule,
    ChipsModule,
    FormsModule,
    InputTextareaModule,
    BadgeModule,
  ],
  providers: [NotesService],
  selector: 'notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
})
export class NotesComponent implements OnInit, OnDestroy, OnChanges {

  @Input() job: ISqlJob = {} as ISqlJob;
  @Input() currentUser: any = {} as any;

  notes: any[] = [];
  datePipe: DatePipe = new DatePipe('en-US');

  constructor(private notesService: NotesService, private cdr: ChangeDetectorRef) { }

  async ngOnInit(): Promise<void> {
    this.notes = await this.notesService.getScJobNotesById(this.job.scJobId) || []
    console.log(this.notes);
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
  }

  ngOnDestroy(): void {

  }

  async addNote(newNote: any): Promise<void> {
    const note = await this.notesService.createScJobNote(this.job.scJobId, newNote.value, false);
    if (note) {
      this.notes.push(note);
      // sort the notes by date descending
      this.notes.sort((a, b) => {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
      });
      newNote.value = '';
    }
  }

}
