export enum JOB_STATUS {
  RECEIVED = 1,
  ASSIGNED = 2,
  INSURED = 3,
  SCHEDULED = 4,
  QC = 6,
  DELIVERED = 7,
  CANCELLED = 8,
  ON_HOLD = 9,
  QC_ISSUE = 10,
  REASSIGN = 11,
  AUTO_ASSIGN = 12,
  MANUAL_ASSIGN = 13,
  EMAIL_ASSIGNMENT = 14
}
