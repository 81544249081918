import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { LogoutService } from './app.logout.service';

@Component({
  standalone: true,
  imports: [ToastModule],
  selector: 'app-logout',
  templateUrl: './app.logout.component.html',
  styleUrls: ['./app.logout.component.scss']
})
export class AppLogoutComponent implements OnInit {
  logOutConfirm = true

  constructor(
    private router: Router,
    private messageService: MessageService,
    private logoutService: LogoutService,
  ) {}

  ngOnInit(): void {
    this.logout();
  }

  async logout() {
    try {
      await this.logoutService.logout();
    } catch (error: any) {
      console.error('Failed to log out:', error);
    } finally {
      this.logoutService.logout();
      this.messageService.add({ severity:'success', summary: 'Logged Out', detail: 'You have been successfully logged out.', key: 'logoutToast' },);
      setTimeout(() => {
        this.router.navigate(['/login']);
      }, 2000);
    }
  }

}
