import { Injectable, Injector, signal, computed } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpBackend } from '@angular/common/http';
import { Router } from '@angular/router';
import { IUserAuth } from './user-auth';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { environment } from'../../environments/environment';
import { firstValueFrom } from 'rxjs';

interface IUserAuthPayload {
  username: string;
  sub: string; 
  roles: number[];
  userProfileId: number; 
}
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private env = environment;
  private static _userAuthSignal = signal<IUserAuth | null>(null);
  private static _accessToken = '';
  private http: HttpClient;

  constructor(private injector: Injector, private httpBackend: HttpBackend) {
    this.http = new HttpClient(this.httpBackend);

    const accessToken = this.getLocalStorageAccessToken();
    if (accessToken && this.isTokenValid(accessToken)) {
      AuthService._accessToken = accessToken;
    }
  }

  async userAuth(): Promise<IUserAuth|null> {
    if (AuthService._userAuthSignal() === null) {
      const accessToken = this.getLocalStorageAccessToken();
      if (accessToken && this.isTokenValid(accessToken)) {
        AuthService._accessToken = accessToken;
        const userInfo = await this.getUserInfo(accessToken);
        AuthService._userAuthSignal.set({
          accessToken,
          userName: `${userInfo?.FirstName || 'Unknown'} ${userInfo?.LastName || 'User'}`,
          roles: userInfo.roles,
          userProfileId: userInfo.userProfileId,
        });
      } else {
        AuthService._userAuthSignal.set(null);
      }
    }
    return AuthService._userAuthSignal();
  }
  
  get token(): string {
    return AuthService._accessToken;
  }

  getLocalStorageAccessToken(): string {
    try {
      const userAuth = localStorage.getItem('userAuth');
      if (!userAuth) {
        return '';
      }
      const { accessToken } = JSON.parse(userAuth);
      return accessToken;
    } catch (error) {
      console.error('Failed to parse access token from local storage:', error);
      return '';
    }
  }

  async login(loginName: string, password: string): Promise<any|void> {
    try {
      const user: any = await firstValueFrom( this.http
        .post(
          `${this.env.baseApiUrl}/auth/login`,
          {
            loginName,
            password,
          }
        ));

        if (user?.accessToken) {
          const userInfo = await this.getUserInfo(user.accessToken);
          const userAuth = {
            accessToken: user.accessToken,
            userName: `${userInfo?.FirstName || 'Unknown'} ${userInfo?.LastName || 'User'}`,
            roles: userInfo.roles,
            userProfileId: userInfo.userProfileId,
          } as IUserAuth;
          localStorage.setItem('userAuth', JSON.stringify({accessToken: userAuth.accessToken}));
          AuthService._userAuthSignal.set(userAuth);
          return AuthService._userAuthSignal();
        }
    } catch (error) {
      console.error('Failed to login:', error);
      throw error;
    }
  }

  isTokenValid(accessToken: string): boolean {
    try {
      if (!accessToken) {
        return false;
      }

      const decoded = jwtDecode<JwtPayload>(accessToken);
      if (!decoded?.exp) {
        return false;
      }
  
      const date = new Date(0); 
      date.setUTCSeconds(decoded.exp);
      if (date.valueOf() > new Date().valueOf()) {
        return true;
      } else {
        this.logout();
        return false;
      }
    } catch (error) {
      console.error('Failed to validate token:', error);
      return false;
    }
  }

  hasValidToken(): boolean {
    const token = this.getLocalStorageAccessToken();
    return !!token && this.isTokenValid(token);
  }

  logout() {
    const router = this.injector.get(Router);
    AuthService._userAuthSignal.set(null);
    localStorage.removeItem('userAuth');
  }

  decodeUserAuthToken(token: string): IUserAuthPayload|null {
    return jwtDecode<JwtPayload>(token) as IUserAuthPayload|null;
  }

  async getUserInfo(accessToken?: string): Promise<any|void> {
      accessToken = accessToken || AuthService._accessToken;
    if (!accessToken) {
      return;
    }

    const userAuthPayload = this.decodeUserAuthToken(accessToken) as IUserAuthPayload;

    if (userAuthPayload.userProfileId && this.isTokenValid(accessToken)) {
      let userInfo: any;
      const userProfile = await firstValueFrom( 
        this.http.get(`${this.env.baseApiUrl}/users/profile/${userAuthPayload.userProfileId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        ));
                
      if (userProfile) {
        return {
          ...userAuthPayload,
          ...userProfile
        };
      } else {
          return userAuthPayload;
      }
    }
    return userAuthPayload;
  }

  passwordReset(token: string, newPassword: string) {
    const http = this.injector.get(HttpClient);
    return http.post(`${this.env.baseApiUrl}/auth/password-reset`, { token , newPassword });
  }

}
