<div class="p-grid">
  <div class="p-col-12 p-md-6 p-lg-4 w-100 shadow-5">
    <p-card>
        <textarea #newNote pInputTextarea rows="4" placeholder="Enter job a note..." style="width: 100%;"></textarea>
        <br>
        <p-button label="Add Note" (onClick)="addNote(newNote)"></p-button>
    </p-card>
  </div>
  <div class="p-col-12 p-md-6 p-lg-4" *ngFor="let note of notes">
      <p-card [subheader]="datePipe.transform(note.createdAt, 'short') || ''">
        <ng-template pTemplate="header">
          <div class="flex flex-row gap-1" style="padding: 0.75rem;">
            <div>
              <h5>{{(note.user && note.user.FirstName) ? note.user.FirstName + ' ' + note.user.LastName : 'System Generated'}}</h5>
            </div>
            <div *ngIf="note.private">
              <p-badge value="Private Note" severity="danger" />
            </div>
          </div>
        </ng-template>
        <p>{{note.content}}</p>
      </p-card>
    </div>
  </div>
  
  <!-- [(ngModel)]="newNoteContent" -->