import { Component, HostListener, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { JobsService } from '../jobs/jobs.service';
import { ISqlJob } from '../jobs/sql-job.interface';
import { ConfirmationService } from 'primeng/api';
import { Observable, Subject, firstValueFrom } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { InspectionComponent } from '../inspection/inspection.component';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DialogService } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-inspection-guest',
  templateUrl: './inspection-guest.component.html',
  styleUrls: ['./inspection-guest.component.scss'],
  providers: [DialogService, ConfirmationService, DynamicDialogRef, DynamicDialogConfig]
})
export class InspectionGuestComponent implements OnInit, OnDestroy {
  canNavigateAwaySubject: Subject<boolean> = new Subject<boolean>();

  scJob: ISqlJob = {} as ISqlJob;
  isLoading = true;
  hasUnsavedPhotoChanges = false;
  hasUnsavedFormChanges = false;
  isInspectionImmutable = false;
  immutableInspectionStatusIds = [1, 3, 6, 7, 8, 9];
  isLinkValid = false;
  pageTitle = 'Invalid SiteCheck Inspection Form Link';
  isFormClosed = false;

  @HostListener('window:beforeunload', ['$event'])
  public onPageUnload($event: BeforeUnloadEvent): void {
    if (this.hasUnsavedChanges()) {
      $event.returnValue = true;
    }
  }

  ref: DynamicDialogRef | undefined;

  constructor(private activatedRoute: ActivatedRoute,
              private confirmationService: ConfirmationService,
              // private router: Router,
              private jobsService: JobsService,
              private authService: AuthService,
              public config: DynamicDialogConfig,
              private dialogService: DialogService,
              ) { }

  ngOnInit(): void {
    const jobId = this.activatedRoute.snapshot.paramMap.get('jobId') || '';
    const token = this.activatedRoute.snapshot.paramMap.get('token') || '';
    this.jobsService.getScJobByJobIdJwt(jobId, token)
      .then(async scJob => {
        if (scJob) {
          // @ts-ignore
          this.scJob = scJob;
          this.pageTitle = `CSI/SiteCheck Inspection Submission for Job # ${scJob.scJobId}`;
          this.isInspectionImmutable = this.immutableInspectionStatusIds.includes(this.scJob.scJobStatusId);
          this.isLinkValid = true;
          if ( !this.isInspectionImmutable && this.isLinkValid ) {
            await this.authService.login('sitecheck-guest2', '1234+Five');
            this.openInspection(this.scJob);
          }
        }
        this.isLoading = false;
      });
  }

  ngOnDestroy(): void {
    if (this.ref) {
      this.ref.close();
    }
  }

  hasUnsavedChanges(): boolean {
    return this.hasUnsavedFormChanges || this.hasUnsavedPhotoChanges;
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    if (this.hasUnsavedChanges()) {
      let work = `work`;
      if (this.hasUnsavedFormChanges && this.hasUnsavedPhotoChanges) {
        work = 'photos and inspection form changes';
      } else if (this.hasUnsavedFormChanges && !this.hasUnsavedPhotoChanges) {
        work = `inspection form changes`;
      } else if (this.hasUnsavedPhotoChanges && !this.hasUnsavedFormChanges) {
        work = `photo changes`;
      }
      this.confirmationService.confirm({
        key: 'inspectionGuestConfirmDialog',
        message: `It appears you have unsaved ${work}, are you sure you want to leave this screen?`,
        defaultFocus: 'reject',
        closeOnEscape: true,
        accept: () => {
          this.canNavigateAwaySubject.next(true);
        },
        reject: () => {
          this.canNavigateAwaySubject.next(false);
        }
      });
      return this.canNavigateAwaySubject;
    }
    return true;
  }

  openInspection(job: ISqlJob): void {
    const ref = this.dialogService.open(InspectionComponent, {
      data: {
        job
      },
      header: `Inspection Photos / Form for Job# ${job.scJobId}`,
      width: '100vw',
      height: '100vh',
      showHeader: true,
      focusOnShow: true,
      closeOnEscape: false,
      contentStyle: { 'height': '100%', 'overflow-y': 'hidden !important' },
      closable: false,
    });

    ref.onClose.subscribe(() => {
      this.isFormClosed = true;
    });
  }

}
