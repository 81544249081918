<div class="exception-body notfound">
    <div class="exception-panel">
        <div class="exception-image">
            <img src="assets/layout/images/exception/icon-404.png">
        </div>

        <div class="exception-detail">
            <h1>PAGE NOT FOUND</h1>
            <p>Requested resource is not available.</p>
            <p-button label="GO TO DASHBOARD" [routerLink]="['/']"></p-button>
        </div>
    </div>
</div>
