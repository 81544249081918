export enum UserRole {
    GUEST,
    USER,
    ADMIN,
    REP
  }

export const roleNames = {
    [UserRole.GUEST]: 'Guest',
    [UserRole.USER]: 'Coordinator',
    [UserRole.ADMIN]: 'Admin',
    [UserRole.REP]: 'Rep'
  };