import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpRequest} from '@angular/common/http';
import { IFile } from './upload/upload.models';
import {firstValueFrom, Observable} from 'rxjs';
import {PhotoLabel} from '../settings/photo-labels/classes/photo-label.class';
import {SettingsService} from '../settings/settings.service';
import { environment } from'../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PhotosService {
  env = environment;

  constructor(
    private http: HttpClient,
    private settingsService: SettingsService
  ) {
  }

  getSignedUrls(files: IFile[]): Promise<any> {
    return this.http.post(`${this.env.baseApiUrl}/aws/sign-for-put/`, files)
      .toPromise()
      .then( data => {
        return data;
      });
    }

    uploadPhotoS3(file: any): Observable<any> {
      const headers = new HttpHeaders({
        'Content-Type': file.type,
      });
      const uploadReq = new HttpRequest(
        'PUT',
        file.url,
        file,
        {
          headers,
          reportProgress: true,
        }
      );
      return this.http.request(uploadReq);
    }

    savePhotos(jobId: string, photos: any): Promise<any> {
      return this.http.put(`${this.env.baseApiUrl}/jobs/${jobId}`, { inspectionPhotos: photos })
        .toPromise()
        .then(job => {
          return job;
        });
    }

    async getJob(jobId: string): Promise<any> {
      const job = await firstValueFrom( this.http.get(`${this.env.baseApiUrl}/jobs/${jobId}`) );
      return job;
    }

    async getS3Photo(url: string): Promise<any> {
      try {
        const photos = await firstValueFrom( this.http.get(url, { responseType: 'blob'}) );
        return photos;
      } catch (error) {
        console.log(error);
      }
    }
    
    deleteS3Photo(bucket: string, key: string): Promise<any> {
      return this.http.post(`${this.env.baseApiUrl}/aws/delete-s3-object`, { bucket, key })
        .toPromise()
        .then(res => {
          // console.log(res);
          return res;
        });
    }

    getS3PhotoBucket(): Promise<any> {
      return this.http.get(`${this.env.baseApiUrl}/aws/get-s3-bucket-name`)
        .toPromise()
        .then( data => {
          // @ts-ignore
          return data.bucket;
        })
        .catch( err => {
          console.log(err);
        });
    }

    getPhotoLabels(): Promise<PhotoLabel[]> {
      return this.settingsService.getPhotoLabels()
        .then(photoLabels => {
          return photoLabels;
        });
      }

}
