import { Component, ViewChild, ElementRef, HostListener, OnInit } from '@angular/core';
import { NgForm, ReactiveFormsModule, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AuthService } from '../auth/auth.service';
import { LoginService } from './app.login.service';
import { environment } from '../../environments/environment';
@Component({
  selector: 'app-login',
  templateUrl: './app.login.component.html',
  styleUrls: ['./app.login.component.scss']
})
export class AppLoginComponent implements OnInit {
  passwordResetVisible = false;
  passwordResetEmail = '';
  loginForm: FormGroup;
  env: any;


  constructor(
    private authService: AuthService,
    private router: Router,
    private messageService: MessageService,
    private loginService: LoginService,
    private fb: FormBuilder,
  ) {
    this.env = environment;
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.authService.userAuth().then((userAuth) => {
      if (userAuth) {
        if (this.authService.hasValidToken()) {
          setTimeout(async () => {
            // const nav = await this.router.navigate([`/dashboard`]);
            (window as any).location.href = this.env.baseUrl + '/';
          }, 1000);
        }
      }
    });
  }

  async login() {
    try {
      if (this.loginForm.invalid) {
        this.loginForm.markAllAsTouched();
        return;
      }
      const user = await this.authService.login(
        this.loginForm.get('username')?.value, 
        this.loginForm.get('password')?.value
      );
      if (user) {
        setTimeout(async () => {
          try {
            (window as any).location.href = this.env.baseUrl + '/';
            // const nav = await this.router.navigate([`/dashboard`]);
          } catch (error) {
            console.error('Failed to navigate to dashboard:', error);
          }  
        }, 1000);
      }
    } catch (error: any) {
      console.error('Failed to login:', error);
      this.messageService.add({
        severity: 'error',
        summary: 'Unable to login',
        detail: 'Please check your username and password and try again.',
        key: 'loginToast'
      });

    }
  }

  async submitResetPassword(passwordResetEmail: string): Promise<void> {
    try {
      await this.loginService.passwordReset(passwordResetEmail);
      this.passwordResetVisible = false;
      this.messageService.add({ severity: 'success', summary: 'Password Reset', detail: 'Password reset request submitted', key: 'loginToast' },);  
    } catch (error: any) {
      console.error('Failed to reset password:', error);
      this.messageService.add({
        severity: 'error',
        summary: 'Password Reset',
        detail: 'Password reset request failed',
        key: 'loginToast'
      });
      
    }
  }

  isFieldInvalid(fieldName: string): boolean {
    const control = this.loginForm.get(fieldName);
    return control?.invalid && (control.dirty || control.touched) || false;
  }

}
