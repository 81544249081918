<!-- <div><h2>User Profiles</h2></div>
<p-table #dt 
    [value]="virtualUserProfiles" 
    [lazy]="true"
    [lazyLoadOnInit]="true"
    [scrollable]="true" 
    scrollHeight="400px" 
    [columns]="cols"  
    (onLazyLoad)="getUserProfiles($event)"
    [rows]="30"
    [totalRecords]="totalRecords"
    [virtualScroll]="true" 
    [virtualScrollItemSize]="46">
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th *ngFor="let col of columns" style="width: 20%;">
                {{ col.header }}
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-columns="columns">
        <tr style="height:46px">
            <td *ngFor="let col of columns">
                {{rowData[col.field]}}
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="loadingbody" let-columns="columns">
        <tr style="height:46px">
            <td *ngFor="let col of columns; let even = even">
                <p-skeleton [ngStyle]="{'width': even ? (col.field === 'year' ? '30%' : '40%') : '60%'}" />
            </td>
        </tr>
    </ng-template>
</p-table> -->

<p-toast #usersToast></p-toast>
<p-confirmDialog #usersConfirmDialog key="usersConfirmDialog" header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<div class="p-grid">
  <div class="p-col-12 h-100">
    <div class="p-panel h-100">
      <div class="p-panel-header">
        <h4>User Profiles</h4>
      </div>
      <div class="p-panel-content">
        <p-table #usersTable
          [value]="userProfiles"
          styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm"
          [globalFilterFields]="['FirstName', 'LastName', 'USerId', 'EmailId']"
          [scrollable]="true"
          scrollHeight="65vh"
          [resizableColumns]="true"
          [filterDelay]="500"
          [rowHover]="true"
          [columns]
          >
          <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngFor="let col of columns" style="width: 20%;">
                    {{ col.header }}
                </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body"  let-rowData="userProfiles" let-rowIndex="rowIndex" let-columns="columns">
            <tr style="height:46px">
                <td *ngFor="let col of columns">
                    {{rowData[col.field]}}
                </td>
            </tr>
        </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>
