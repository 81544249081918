import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NotesService } from '../notes/notes.service';
import { AppService } from '../app.service';
import { DateTime } from 'luxon';
import { UserRole } from '../users/roles.enum'
import { firstValueFrom } from 'rxjs';
import { JOB_STATUS } from './job-status.enum';
import { Formio } from '@formio/js';
import { environment } from '../../environments/environment';
import { FormsService } from '../forms/forms.service';

@Injectable({
  providedIn: 'root'
})
export class JobsService {
  private env = environment;
  
  constructor(
    private http: HttpClient,
    private notesService: NotesService,
    private app: AppService,
    private formsService: FormsService,
  ) { }

  getJobs(userProfileId: number): Promise<any> {
    
    let query = '';
    if (userProfileId) {
      query = `?userProfileId=${userProfileId}`;
    }
    
    return this.http.get(`${this.env.baseApiUrl}/jobs${query}`)
      .toPromise()
      .then( data => {
        return data;
      });
  }

  sendLinkEmail(jobId: string, scJobId: number): Promise<any> {
    return this.http.get(`${this.env.baseApiUrl}/jobs/send-link-email/${jobId}`)
      .toPromise()
      .then( data => {
        this.notesService.createScJobNote(scJobId,
          `Job inspection form and photo upload link emailed to inspector.`, true)
          .catch(e => console.error(`Unable to save note for inspection form and photo upload link email.\n${e}`));
        return data;
      });
  }

  getInspectionFormLink(jobId: string, scJobId: number): Promise<any> {
    return this.http.get(`${this.env.baseApiUrl}/jobs/inspection-form-link/${jobId}`)
      .toPromise()
      .then( data => {
        return data;
      });
  }

  getJobById(jobId: string): Promise<any> {
    return this.http.get(`${this.env.baseApiUrl}/jobs/${jobId}`)
      .toPromise()
      .then( data => {
        return data;
      });
  }

  getScJobByJobId(jobId: string): Promise<any> {
    return this.http.get(`${this.env.baseApiUrl}/jobs/sc-job/${jobId}`)
      .toPromise()
      .then( data => {
        return data;
      });
  }

  getScJobByJobIdJwt(jobId: string, jwt: string): Promise<any> {
    return this.http.post(`${this.env.baseApiUrl}/jobs/sc-job-jwt/${jobId}`, { token: jwt })
      .toPromise()
      .then( data => {
        return data;
      });
  }

  updateJob(jobId: string, data: any): Promise<any> {
    return firstValueFrom( this.http.put(`${this.env.baseApiUrl}/jobs/${jobId}`, data) );
  }

  transferJobToLegacy(jobId: string): Promise<any> {
    return this.http.get(`${this.env.baseApiUrl}/legacy-transfer/push-photos/${jobId}`)
      .toPromise()
      .then(results => {
        return results;
      });
  }

  postFormDataLegacy(jobId: string): Promise<any> {
    return this.http.get(`${this.env.baseApiUrl}/jobs/post-form-data-legacy/${jobId}`)
      .toPromise()
      .then( resp => {
        return resp;
      });
  }

  getUserProfile(): any {
    const userInfo = this.app.currentUser;
    console.log(userInfo);
    return userInfo;
  }

  calculateDueDate(requestDate: string, userRole: UserRole): Date {
    let dueDate: DateTime;

    switch (userRole) {
      case UserRole.REP:
        dueDate = DateTime.fromISO(requestDate).plus({ weeks: 2 });
        break;
      case UserRole.ADMIN:
      case UserRole.USER:
        dueDate = DateTime.fromISO(requestDate).plus({ days: 30 });
        break;
      default:
        throw new Error(`Invalid user role: ${userRole}`);
    }

    return dueDate.toJSDate();
  }

  async updateScheduledDate(jobId: number, scheduledDate: string): Promise<any> {
    return firstValueFrom( this.http.put(`${this.env.baseApiUrl}/jobs/prisma/${jobId}`, { scheduledDate: scheduledDate }) );
  }
  
  async getJobRequest(jobId: number): Promise<any> {
    return firstValueFrom( this.http.get(`${this.env.baseApiUrl}/jobs/prisma/${jobId}`) );
  }

  async getActiveJobRequests(): Promise<any> {
    return firstValueFrom( this.http.get(`${this.env.baseApiUrl}/jobs/prisma/activeJobRequests`) );
  }

  async updateJobStatusId(jobId: number, jobStatusId: JOB_STATUS): Promise<any> {
    return firstValueFrom( this.http.put(`${this.env.baseApiUrl}/jobs/prisma/${jobId}`, { JobStatusId: jobStatusId }) );
  }

  getDeliveredJobs(daysBack: number): Promise<any> {
    return firstValueFrom( this.http.get(`${this.env.baseApiUrl}/jobs/delivered/${daysBack}`) );
  }

  async validateJobForQc(jobId: string, data: any = {}): Promise<any> {
    try {
      const job = await this.getJobById(jobId);
      if (!job) {
        throw new Error(`Job not found: ${jobId}`);
      }
      const form: any = await this.formsService.getFormById(job.formId);
      if (!form) {
        throw new Error(`Form not found: ${job.formId}`);
      }
      let isPhotoRequirementValid: boolean;
      if (form.photosRequired) {
        isPhotoRequirementValid = job?.inspectionPhotos && job?.inspectionPhotos.length > 0;
      } else {
        isPhotoRequirementValid = true;
      }
      if (form.photosRequired && data.photos && data.photos.length > 0) {
        isPhotoRequirementValid = data.photos.length > 0;
      }
      const dummyElement = document.createElement('div');
      const formio = await Formio.createForm(dummyElement, JSON.parse(form.inspectionForm), { submission: { data: job.inspectionData }});
      const isFormValid = await formio.checkValidity(null, true);
      let fieldsWithErrors: string[] = [];
      if (!isFormValid) {
        formio.errors.forEach((error: any) => {
          fieldsWithErrors.push(error.component.label || 'Unknown Field');
        });
        return { 
          isPhotoRequirementValid,
          isFormValid: false,
          errors: formio.errors,
          fieldsWithErrors,
          isReadyForQc: isPhotoRequirementValid && isFormValid,
        };
      } else {
        return { 
          isPhotoRequirementValid,
          isFormValid: true,
          errors: [],
          fieldsWithErrors,
          isReadyForQc: isPhotoRequirementValid && isFormValid,
        };
      }
    } catch (error) {
      console.error('Error validating job for QC:', error);
      return { isPhotoRequirementValid: false, isFormValid: false, errors: [error], fieldsWithErrors: [], isReadyForQc: false };
    }
  }
}
