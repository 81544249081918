<!-- <p-toast></p-toast> -->

<div class="p-grid">
  <div class="p-col-12">
    <div class="p-panel">
      <div class="p-panel-header">
        <h4>Forms Manager</h4>
      </div>
      <div class="p-panel-content">
        <p-table [value]="forms" [paginator]="true" [rows]="10" [showCurrentPageReport]="true" dataKey="_id" editMode="row"
                 currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rowsPerPageOptions]="[10,25,50]">
          <ng-template pTemplate="header">
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th>SC Type ID</th>
              <th style="width:15rem"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-form let-editing="editing" let-ri="rowIndex">
            <tr [pEditableRow]="form">
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input pInputText type="text" placeholder="Form name..." [(ngModel)]="form.name" style="width: 100%" required>
                  </ng-template>
                  <ng-template pTemplate="output">
                    <span style="width: 100%">{{form.name}}</span>
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input pInputText type="text" [(ngModel)]="form.description" style="width: 100%">
                  </ng-template>
                  <ng-template pTemplate="output">
                    <span style="width: 100%">{{form.description}}</span>
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input pInputNumber type="number" [(ngModel)]="form.scFormTypeId" style="width: 12rem" required>
                  </ng-template>
                  <ng-template pTemplate="output">
                    <span style="width: 12rem">{{form.scFormTypeId}}</span>
                  </ng-template>
                </p-cellEditor>
              </td>
<!--              <td>{{form.createdAt}}</td>-->
<!--              <td>{{form.updatedAt}}</td>-->
              <td style="text-align:center">
                <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditInit(form)" class="p-button-rounded p-button-text"></button>
                <button *ngIf="!editing" pButton pRipple type="button"
                        icon="pi pi-file" (click)="editInspectionForm(form)"
                        class="p-button-rounded p-button-text" pTooltip="Edit the inspection form"
                        tooltipPosition="top"></button>
                <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSave(form)" class="p-button-rounded p-button-text p-button-success p-mr-2"></button>
                <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancel(form, ri)" class="p-button-rounded p-button-text p-button-danger"></button>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="paginatorleft">
            <button pButton type="button" icon="pi pi-plus" styleClass="p-button-text"></button>
          </ng-template>
          <ng-template pTemplate="paginatorright">
            <button pButton type="button" icon="pi pi-cloud" styleClass="p-button-text"></button>
          </ng-template>
        </p-table>

      </div>
    </div>
  </div>
</div>
