import { Component, OnInit, Input, OnDestroy, OnChanges, SimpleChange, SimpleChanges, ChangeDetectorRef} from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { NotesComponent } from '../notes.component';
import { ISqlJob } from 'src/app/jobs/sql-job.interface';

@Component({
  standalone: true,
  imports:[ NotesComponent ],
  providers: [],
  selector: 'notes-dialog',
  templateUrl: './notes-dialog.component.html',
  styleUrls: ['./notes-dialog.component.scss'],
})
export class NotesDialogComponent implements OnInit, OnDestroy, OnChanges {

  job: ISqlJob = {} as ISqlJob;
  currentUser: any = {} as any;

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) { }

  async ngOnInit(): Promise<void> {
    this.job = this.config.data.job;
    this.currentUser = this.config.data.currentUser;
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
  }

  ngOnDestroy(): void {

  }

}
