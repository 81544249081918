import { Component, OnInit } from '@angular/core';
import {BreadcrumbService} from '../../app.breadcrumb.service';
import { FormsService } from '../forms.service';
import { SelectItem } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { FormEditorComponent } from '../form-editor/form-editor.component';
import 'ace-builds/src-noconflict/ace';
// import 'jsoneditor/dist/jsoneditor.css';

import { Form } from '../form.interface';

@Component({
  selector: 'app-forms-manager',
  templateUrl: './forms-manager.component.html',
  styleUrls: ['./forms-manager.component.scss']
})
export class FormsManagerComponent implements OnInit {
  forms: any;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private formsService: FormsService,
    private messageService: MessageService,
    public dialogService: DialogService,
  ) {
    this.breadcrumbService.setItems([
      {label: 'Forms Manager', routerLink: ['/forms-manager']}
    ]);
  }

  ngOnInit(): void {
    this.formsService.getForms()
      .then(forms => {
        this.forms = forms;
      });
  }

  onRowEditInit(form: Form) {
    // this.clonedProducts[product.id] = {...product};
    console.log(form);
  }

  onRowEditSave(form: Form) {
    if (form.scFormTypeId > 0) {
      // delete this.clonedProducts[product.id];
      this.messageService.add({severity: 'success', summary: 'Success', detail: 'Form has been updated'});
    }
    else {
      this.messageService.add({severity: 'error', summary: 'Error', detail: 'Invalid SC_FormType ID'});
    }
  }

  onRowEditCancel(form: Form, index: number) {
    // this.products2[index] = this.clonedProducts[product.id];
    // delete this.clonedProducts[product.id];
  }

  editInspectionForm(form: Form) {
    console.log('Clicked edit inspection form.', form);
    const ref = this.dialogService.open(FormEditorComponent, {
      data: {
        form
      },
      header: `${form.name} - ${form.inspectionForm ? '' : 'New '}Inspection Form`,
      width: '85%',
      showHeader: true,
      closeOnEscape: false,
    });

    ref.onClose.subscribe((modifiedForm: Form) => {
      if (modifiedForm) {
        console.log(modifiedForm);
        form = modifiedForm;
        this.messageService.add({severity: 'info', summary: 'Inspection form saved', detail: modifiedForm.name});
      }
    });
  }



}
