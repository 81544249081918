import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhotoLabelsComponent } from './photo-labels.component';
import { TableModule } from 'primeng/table';
import { ToolbarModule } from 'primeng/toolbar';
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  declarations: [PhotoLabelsComponent],
  imports: [
    CommonModule,
    TableModule,
    ToolbarModule,
    ToastModule,
    ButtonModule,
    InputTextModule,
    FormsModule,
    TooltipModule
  ]
})
export class PhotoLabelsModule { }
