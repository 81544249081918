<div class="layout-breadcrumb">
    <ul>
        <li><a routerLink=""><i class="pi pi-home"></i></a></li>
        <li><i class="pi pi-chevron-right"></i></li>
        <ng-template ngFor let-item let-last="last" [ngForOf]="items">
            <li>
                <a [routerLink]="item.routerLink" *ngIf="item.routerLink">{{item.label}}</a>
                <ng-container *ngIf="!item.routerLink">{{item.label}}</ng-container>
            </li>
            <li *ngIf="!last"><i class="pi pi-chevron-right"></i></li>
        </ng-template>
    </ul>
</div>
