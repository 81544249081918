<div class="exception-body error">
    <div class="exception-panel">
        <div class="exception-image">
            <img src="assets/layout/images/exception/icon-error.png">
        </div>

        <div class="exception-detail">
            <h1>ERROR OCCURED</h1>
            <p>Something went wrong.</p>
            <p-button label="GO TO DASHBOARD" [routerLink]="['/']"></p-button>
        </div>
    </div>
</div>
