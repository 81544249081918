<p-toast key="inspectionToast"></p-toast>
<p-confirmDialog key="inspectionConfirmDialog" header="Inspection Form Confirmation" width="425"
  icon="pi pi-exclamation-triangle"></p-confirmDialog>
<div class="dialog-container" style="height: 100% !important; min-height: 100% !important">
  @if (!isInspectionImmutable && isLinkValid) {
    <div class="flex flex-column h-100 border-gray-200 border-round border-2 shadow-5 px-2 pt-2 tabview-container">
      <p-tabView class="p-element">
        <p-tabPanel header="Photos" leftIcon="pi pi-image" tooltip="Upload photos for this inspection.">
          <app-upload [scJob]="scJob" (hasUnsavedPhotoChanges)="hasUnsavedPhotoChanges=$event"
            (hasPhotos)="hasPhotos.set($event)"></app-upload>
        </p-tabPanel>
        <p-tabPanel header="Form" leftIcon="pi pi-file" tooltip="View and fill the inspection form.">
          <app-inspection-form #inspectionFormComponent [scJob]="scJob" [showButtons]="false" [showFormioButtons]="false" (hasUnsavedFormChanges)="hasUnsavedFormChanges=$event" [isFormClosed]="isFormClosed()"
          [isSaveFinal]="isSaveFinal()" (onFormStateChange)="formStateChange($event)"></app-inspection-form>
        </p-tabPanel>
      </p-tabView>
    </div>
    <div class="flex flex-column button-container">
      <!-- <p-divider></p-divider> -->
      <div class="flex flex-row justify-content-end flex-wrap gap-2">
        <p-button label="Save" severity="primary" (click)="saveForm()"></p-button>  
        <p-button label="Submit to QC" severity="warning" (click)="qcSubmit()" ></p-button>
        <p-button label="Close" severity="danger" (click)="closeDialog()"></p-button>
      </div>
    </div>
  }
</div> 
