import { Injectable } from '@angular/core';
import { PhotoLabel } from './photo-labels/classes/photo-label.class';
import * as Http from 'http';
import {HttpClient} from '@angular/common/http';
import {PhotoLabelsComponent} from './photo-labels/photo-labels.component';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  private env = environment;

  constructor(private http: HttpClient) { }

  getPhotoLabels(): Promise<PhotoLabel[]> {
    return this.http.get(`${this.env.baseApiUrl}/settings/photo-labels`)
      .toPromise()
      .then(data => {
        return data as PhotoLabel[];
      });
  }

  savePhotoLabels(photoLabels: any): Promise<PhotoLabel[]> {
    return this.http.put(`${this.env.baseApiUrl}/settings/photo-labels`, photoLabels)
      .toPromise()
      .then( data => {
        return data as PhotoLabel[];
      });
  }

  syncImageCaptions(): Promise<any> {
    return this.http.get(`${this.env.baseApiUrl}/legacy-transfer/sync-image-captions-table`)
      .toPromise()
      .then(res => {
        console.log(res);
      })
      .catch(e => {
        console.log(`Unable to sync image captions on legacy data API.\n${e}`);
      });
  }
}
