import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhotoLabelsModule } from './photo-labels/photo-labels.module';


@NgModule({
  declarations: [],
  imports: [
    PhotoLabelsModule,
    CommonModule
  ]
})
export class SettingsModule { }
