import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Form} from './form.interface';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FormsService {
  private env = environment;

  constructor(private http: HttpClient) { }

  getForms() {
    return this.http.get(`${this.env.baseApiUrl}/forms`)
      .toPromise()
      .then( data => {
        return data;
      });
  }

  getFormById(id: string) {
    return this.http.get(`${this.env.baseApiUrl}/forms/${id}`)
      .toPromise()
      .then( form => {
        return form;
      });
  }

  updateForm(form: any) {
    return this.http.put(`${this.env.baseApiUrl}/forms/${form._id}`, form)
      .toPromise()
      .then( data => {
        return data;
      });
  }
}
