import { Component, OnInit, Output } from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {ISqlJob} from '../../jobs/sql-job.interface';

@Component({
  selector: 'app-inspection-form-dialog',
  templateUrl: './inspection-form-dialog.component.html',
  styleUrls: ['./inspection-form-dialog.component.scss']
})
export class InspectionFormDialogComponent implements OnInit {
  scJob: ISqlJob = {} as ISqlJob;

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) { }

  ngOnInit(): void {
    this.scJob = this.config.data.job;
  }

  onClose(e: any): void {
    console.log(e);
    this.ref.close(null);
  }

}
