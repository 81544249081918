import { Injectable, computed, effect } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { IUserAuth } from './auth/user-auth';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  currentUser: IUserAuth | null = null;

  constructor(private auth: AuthService) {}

  async getCurrentUser(): Promise<IUserAuth | null> {
    const userAuth = await this.auth.userAuth();
    return userAuth;
  }
}