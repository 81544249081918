import { Component, OnInit, ViewChild } from '@angular/core';
import { UsersService } from './users.service';
import { UserProfileDto, UserProfilesDto, UserProfilesResponse } from 'csi-sitecheck-types/dist/types/user-profile.types';
import { firstValueFrom } from 'rxjs';
import { TableModule, Table } from 'primeng/table';
import { LazyLoadEvent, SortMeta } from 'primeng/api';
import { NgFor, CommonModule } from '@angular/common';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { Loading } from 'notiflix';

type TableLazyLoadEvent = LazyLoadEvent & { 
  first: number; 
  rows: number;
  sortField: string | string[];
  sortOrder: number;
  filters: any;
  globalFilter: string | string[];
  multiSortMeta: SortMeta[];
  forceUpdate: Function;
};

interface Column {
  field: string;
  header: string;
}

@Component({
  selector: 'users',
  standalone: true,
  imports: [TableModule, CommonModule, NgFor, ToastModule, ConfirmDialogModule],
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UserComponent implements OnInit {
  userProfiles: UserProfilesDto = [];
  virtualUserProfiles: UserProfilesDto = [];
  nextCursor = 0;
  totalRecords = 0;
  cols!: Column[];
  isLoading = false; // Flag to prevent multiple simultaneous calls
  allDataLoaded = false; // Flag to stop loading when all data is loaded
  @ViewChild('dt') userProfileTable: Table = {} as Table;

  constructor(private usersService: UsersService) {}

  ngOnInit(): void {
    this.cols = [
      { field: 'FirstName', header: 'First Name' },
      { field: 'LastName', header: 'Last Name' },
      { field: 'UserID', header: 'User ID' },
      { field: 'EmailID', header: 'Email' },
    ];
    this.getUserProfiles();
  }

  async getUserProfiles(event?: any): Promise<void> {
    // Prevent the function from being called if data is already being loaded or all data has been fetched
    if (this.isLoading || this.allDataLoaded) {
      return;
    }

    this.isLoading = true; // Set loading flag
    Loading.standard('Loading user profiles...');

    try {
      console.log('Fetching user profiles...', event ? event : 'No event passed');
      console.log('Next cursor:', this.nextCursor);

      const userProfilesResponse = await this.usersService.getUserProfiles() as UserProfilesResponse;

      if (userProfilesResponse) {
        this.userProfiles = [...userProfilesResponse.userProfiles];
        // this.userProfiles = [...this.userProfiles, ...userProfilesResponse.userProfiles];
        // this.virtualUserProfiles = [...this.virtualUserProfiles, ...userProfilesResponse.userProfiles];
        // this.nextCursor = userProfilesResponse.nextCursor || 0;
        // this.totalRecords = userProfilesResponse.totalRecords;

        // // Check if all data has been loaded
        // if (this.userProfiles.length >= this.totalRecords) {
        //   this.allDataLoaded = true; // Stop further loading
        // }
      }
    } catch (error) {
      console.error('Error fetching user profiles:', error);
    } finally {
      Loading.remove(); 
      this.isLoading = false; // Reset loading flag
    }
  }
}